import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect } from 'react';

import { getMe } from '@/common/api/requests';
import { User } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';

export const useUser = () => {
  const userStore = useContext(UserStoreContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (userStore.api && 'setQueryClient' in userStore.api) {
      userStore.api.setQueryClient(queryClient);
    }
  }, [userStore.api, queryClient]);

  const accessToken = userStore.getAccessToken();
  const isAuthenticated = Boolean(accessToken);

  const clearUserCache = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useQuery<User>({
    queryKey: ['user', accessToken],
    queryFn: () => getMe(),
    enabled: isAuthenticated,
    retry: 1,
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });

  useEffect(() => {
    if (user) {
      userStore.setUser(user);
    } else if (isError && isAuthenticated) {
      userStore.logout(clearUserCache);
    }
  }, [user, isError, isAuthenticated, userStore, clearUserCache]);

  return {
    user: userStore.user,
    isLoading,
    isError,
    refetch,
  };
};
