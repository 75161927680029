import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import { FacilityStatus } from '@/common/constants';

import { CommonIcon, CommonIconType } from '../common-icon';
import styles from './common-timer.module.css';
import { CommonTimerProps } from './common-timer.types';

export const CommonTimer = ({
  facility,
  updateFacilityStatus,
  timerClassName,
}: CommonTimerProps) => {
  const {
    targetDate,
    timerLabel,
    timerIcon,
  }: {
    targetDate: dayjs.Dayjs | null;
    timerLabel: string;
    timerIcon: CommonIconType;
  } = useMemo(() => {
    const now = dayjs();
    if (facility.status === FacilityStatus.Active) {
      if (dayjs(facility.endsAt).diff(now) <= 0) {
        return {
          targetDate: null,
          timerLabel: 'Скоро закроется',
          timerIcon: CommonIconType.Time,
        };
      }
      return {
        targetDate: dayjs(facility.endsAt),
        timerLabel: 'Закроется через',
        timerIcon: CommonIconType.Time,
      };
    } else if (facility.status === FacilityStatus.Draft) {
      if (dayjs(facility.startsAt).diff(now) <= 0) {
        return {
          targetDate: null,
          timerLabel: 'Скоро откроется',
          timerIcon: CommonIconType.Time,
        };
      }
      return {
        targetDate: dayjs(facility.startsAt),
        timerLabel: 'Откроется через',
        timerIcon: CommonIconType.Time,
      };
    } else if (facility.status === FacilityStatus.Completed) {
      return {
        targetDate: null,
        timerLabel: 'Закрыто',
        timerIcon: CommonIconType.AlarmExclamation,
      };
    } else if (facility.status === FacilityStatus.Cancelled) {
      return {
        targetDate: null,
        timerLabel: 'Отменено',
        timerIcon: CommonIconType.Xcircle,
      };
    }
    return { targetDate: null, timerLabel: '', timerIcon: CommonIconType.Time };
  }, [facility.status, facility.endsAt, facility.startsAt]);

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isLessThan24Hours, setIsLessThan24Hours] = useState(false);

  const getFormattedTime = (milliseconds: number) => {
    if (milliseconds <= 0) return null;
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const seconds = totalSeconds % 60;
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;

    if (days > 0) {
      return `${days}д ${hours}ч ${minutes}м`;
    }

    if (hours > 0) {
      return `${hours}ч ${minutes}м ${seconds}с`;
    }

    if (minutes > 0) {
      return `${minutes}м ${seconds}с`;
    }

    return `${seconds}с`;
  };

  useEffect(() => {
    if (!targetDate) {
      setTimeLeft(0);
      return;
    }

    const updateTimeLeft = () => {
      const now = dayjs();
      const diff = targetDate.diff(now);
      setTimeLeft(diff);
      setIsLessThan24Hours(diff < 24 * 60 * 60 * 1000);
    };

    updateTimeLeft();

    const interval = setInterval(
      () => {
        const now = dayjs();
        const diff = targetDate.diff(now);

        if (
          diff <= 1000 &&
          (facility.status === FacilityStatus.Active ||
            facility.status === FacilityStatus.Draft)
        ) {
          clearInterval(interval);
          updateFacilityStatus(facility.id);
          setTimeLeft(0);
          return;
        }

        setTimeLeft(diff);
      },
      isLessThan24Hours ? 1000 : 60000,
    );

    return () => clearInterval(interval);
  }, [
    targetDate,
    facility.id,
    facility.status,
    updateFacilityStatus,
    isLessThan24Hours,
  ]);

  return (
    <div className={classNames(styles.container, timerClassName)}>
      <CommonIcon type={timerIcon} className={styles.icon} />
      <div className={styles.timerText}>
        {' '}
        <span className={styles.timerLabel}>{timerLabel}</span>
        <div className={styles.time}>{getFormattedTime(timeLeft)}</div>
      </div>
    </div>
  );
};
