import { memo, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { CommonButton, CommonIcon, CommonIconType } from '@/common/components';

import { RoundsStoreContext } from '../../stores';
import { CommonRoundTags } from '../round-tags';
import { SchemeImage } from './components';
import { useRoundHeroCard } from './round-hero-card.domain';
import styles from './round-hero-card.module.css';
import { RoundHeroCardProps } from './round-hero-card.types';

const RoundHeroCardComponent = ({ round }: RoundHeroCardProps) => {
  const {
    mainImage,
    title,
    betsStartsFrom,
    cityAndDistrictName,
    facilitySchemes,
    startsAt,
    numberOfFacilities,
    description,
  } = useRoundHeroCard(round);

  const roundsStore = useContext(RoundsStoreContext);
  const roundLink = `/rounds/${round.id}`;
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef(null);

  const handleRoundCardClick = () => {
    roundsStore.setRound(round);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      { rootMargin: '200px' },
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Link to={roundLink} onClick={handleRoundCardClick}>
      <div
        ref={ref}
        className={styles.heroWrapper}
        style={{
          background: isLoaded
            ? `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${mainImage}) no-repeat center center / cover`
            : 'none',
        }}
      >
        <div className={styles.content}>
          <div className={styles.openDateMobile}>
            <CommonIcon
              type={CommonIconType.Time}
              className={styles.timeIcon}
            />
            <span>Откроется {startsAt}</span>
          </div>
          <div>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.district}>{cityAndDistrictName}</p>
          </div>
          <div className={styles.facilitySchemes}>
            {facilitySchemes.map((scheme) => (
              <SchemeImage key={scheme.id} scheme={scheme} />
            ))}
          </div>
          <div className={styles.openDate}>
            <CommonIcon
              type={CommonIconType.Time}
              className={styles.timeIcon}
            />
            <span>Откроется {startsAt}</span>
          </div>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.roundContainer}>
            <h3 className={styles.cardTitle}>Аукцион - {numberOfFacilities}</h3>
            <div className={styles.description}>
              <div className={styles.descriptionTitle}>Описание</div>
              <p className={styles.descriptionText}>{description}</p>
            </div>
            <CommonRoundTags round={round} />
            <div className={styles.divider} />
            <div className={styles.betWrapper}>
              <span className={styles.betLabel}>Ставки от</span>
              <span className={styles.bet}>{betsStartsFrom}</span>
            </div>
          </div>
          <CommonButton className={styles.moreButton}>
            Узнать подробнее
          </CommonButton>
        </div>
      </div>
    </Link>
  );
};

RoundHeroCardComponent.displayName = 'RoundHeroCard';

export const RoundHeroCard = memo(RoundHeroCardComponent);
