import { Link } from 'react-router-dom';

import { paths } from '@/common/constants';

import styles from './common-footer-logo.module.css';

export const CommonFooterLogo = () => (
  <div className={styles.wrapper}>
    <Link to={paths.getHomePath()} className={styles.logo}>
      MinoTower
    </Link>

    <p className={styles.description}>ООО «Минотауэр»</p>
  </div>
);
