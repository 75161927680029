import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { CommonFooter, CommonHeader, CommonLoader } from '@/common/components';
import { paths } from '@/common/constants';
import { useUser } from '@/common/hooks/use-user';
import { UserStoreContext } from '@/common/stores';

import styles from './navigation-layout.module.css';

export const NavigationLayout = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);
  const { isLoading: isUserLoading } = useUser();

  useEffect(() => {
    const checkAuthentication = () => {
      const hasToken = Boolean(userStore.getAccessToken());

      if (!hasToken) {
        navigate(paths.getSignInPath());
        return;
      }

      if (userStore.user && !userStore.isOnboardingCompleted) {
        navigate(paths.getOnboardingPath());
      }
    };

    checkAuthentication();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'access_token') {
        checkAuthentication();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [userStore.user, userStore.isOnboardingCompleted, navigate, userStore]);

  return isUserLoading ? (
    <div className={styles.loaderWrapper}>
      <CommonLoader />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <CommonHeader />
      </div>
      <div className={styles.content}>
        <Outlet />
        <CommonFooter />
      </div>
    </div>
  );
});
