import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useApi } from '@/common/api';
import { FacilitiesStoreInjector } from '@/modules/facilities/stores/facilities-store';
import { FacilityStoreInjector } from '@/modules/facilities/stores/facility-store';
import { RoundsStoreInjector } from '@/modules/facilities/stores/rounds-store';

import { UserStoreInjector } from './user-store';

const QueryClientConnector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const api = useApi();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (api && 'setQueryClient' in api) {
      api.setQueryClient(queryClient);
    }
  }, [api, queryClient]);

  return children;
};

export const GlobalInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => (
  <UserStoreInjector>
    <QueryClientConnector>
      <FacilitiesStoreInjector>
        <FacilityStoreInjector>
          <RoundsStoreInjector>{children}</RoundsStoreInjector>
        </FacilityStoreInjector>
      </FacilitiesStoreInjector>
    </QueryClientConnector>
  </UserStoreInjector>
);
