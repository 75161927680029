import { MessageInstance } from 'antd/es/message/interface';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import { Api } from '@/common/api';
import { OnboardUserProps } from '@/common/api/requests';
import { Facility, PaginationMeta, User } from '@/common/entities';

const accessTokenKey = 'access_token';

export class UserStore {
  isAuthPending = false;
  isSignInCodeRequested = false;
  requestedPhoneNumber = '';

  isUserLoading = false;
  isRefillRequestLoading = false;
  isUserUpdateLoading = false;
  isUserOnboardLoading = false;

  accessToken: string | null = null;
  user: User | null = null;

  paginationMeta: PaginationMeta | null = null;
  facilities: Facility[] = [];
  isUserFacilitiesLoading = false;
  hasUserFacilityError = false;

  referredUsersPaginationMeta: PaginationMeta | null = null;
  referredUsers: User[] = [];
  isReferredUsersLoading = false;
  hasReferredUsersError = false;

  api: Api | null = null;
  message: MessageInstance | null = null;

  get isOnboardingCompleted() {
    return Boolean(this.user?.isOnboarded);
  }

  get isReferrer() {
    return Boolean(this.user?.referrerUser);
  }

  constructor(api: Api | null, message: MessageInstance | null) {
    makeAutoObservable(this);

    this.api = api;
    this.message = message;

    this.loadAccessToken();
  }

  changePage(page: number) {
    if (this.paginationMeta) {
      this.paginationMeta = {
        ...this.paginationMeta,
        page,
      };
    }
  }

  changeReferredUsersPage(page: number) {
    if (this.referredUsersPaginationMeta) {
      this.referredUsersPaginationMeta = {
        ...this.referredUsersPaginationMeta,
        page,
      };
    }
  }

  get isAuthenticated() {
    return Boolean(this.accessToken);
  }

  loadMe = async (needLoading = true) => {
    if (!this.api) {
      return;
    }

    if (needLoading) {
      runInAction(() => {
        this.isUserLoading = true;
      });
    }

    try {
      const user = await this.api.getMe();

      runInAction(() => {
        this.user = user;
      });

      return user;
    } catch (error) {
      if (this.message) {
        this.message.error('Ошибка при загрузке пользователя');
      }
      return null;
    } finally {
      runInAction(() => {
        this.isUserLoading = false;
      });
    }
  };

  resetAuthFlags = () => {
    runInAction(() => {
      this.isAuthPending = false;
      this.isSignInCodeRequested = false;
      this.requestedPhoneNumber = '';
    });
  };

  requestSignInCode = async (phoneNumber: string) => {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isAuthPending = true;
    });

    try {
      await this.api.requestSignInCode(phoneNumber);

      runInAction(() => {
        this.requestedPhoneNumber = phoneNumber;
        this.isSignInCodeRequested = true;
      });

      if (this.message) {
        this.message.success('Код отправлен');
      }
    } catch (error) {
      if (this.message) {
        const status = (error as AxiosError).response?.status;

        if (status === 400 || status === 404) {
          this.message.error('Пользователь с таким номером не найден');
        } else {
          this.message.error('Произошла ошибка на сервере');
        }
      }
    } finally {
      runInAction(() => {
        this.isAuthPending = false;
      });
    }
  };

  applySignInCode = async (code: string) => {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isAuthPending = true;
    });

    try {
      const [token, user] = await this.api.applySignInCode(
        this.requestedPhoneNumber,
        code,
      );

      this.setAccessToken(token);
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      if (this.message) {
        const status = (error as AxiosError).response?.status;

        if (status === 400) {
          this.message.error('Неверный код');
        } else {
          this.message.error('Ошибка при входе');
        }
      }
    } finally {
      runInAction(() => {
        this.isAuthPending = false;
      });
    }
  };

  requestSignUpCode = async (phoneNumber: string, referralCode: string) => {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isAuthPending = true;
    });

    try {
      await this.api.requestSignUpCode(phoneNumber, referralCode);

      runInAction(() => {
        this.requestedPhoneNumber = phoneNumber;
        this.isSignInCodeRequested = true;
      });

      if (this.message) {
        this.message.success('Код отправлен');
      }
    } catch (error) {
      if (this.message) {
        const body = (error as AxiosError<{ error: string }>).response?.data;

        if (body?.error === 'user_already_exists') {
          this.message.error(
            'Пользователь с таким номером уже зарегистрирован',
          );
        } else if (body?.error === 'referral_code_not_found') {
          this.message.error('Реферальный код не найден');
        } else {
          this.message.error('Ошибка при отправке кода');
        }
      }
    } finally {
      runInAction(() => {
        this.isAuthPending = false;
      });
    }
  };

  applySignUpCode = async (code: string, referralCode: string) => {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isAuthPending = true;
    });

    try {
      const [token, user] = await this.api.applySignUpCode(
        this.requestedPhoneNumber,
        code,
        referralCode,
      );

      this.setAccessToken(token);
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      if (this.message) {
        const body = (error as AxiosError<{ error: string }>).response?.data;

        if (body?.error === 'invalid_auth_code') {
          this.message.error('Неверный код');
        } else {
          this.message.error('Ошибка при регистрации');
        }
      }
    } finally {
      runInAction(() => {
        this.isAuthPending = false;
      });
    }
  };

  loadAccessToken = () => {
    try {
      const token = localStorage.getItem(accessTokenKey);

      if (token) {
        this.accessToken = token;
        return true;
      }
    } catch (error) {
      this.message?.error('Ошибка при получении токена');
    }
    return false;
  };

  getAccessToken = () => {
    if (this.accessToken) {
      return this.accessToken;
    }

    try {
      const token = localStorage.getItem(accessTokenKey);
      if (token) {
        this.accessToken = token;
        return token;
      }
    } catch (error) {
      this.message?.error('Ошибка при получении токена');
    }

    return null;
  };

  setAccessToken = (token: string) => {
    if (!token) return;

    try {
      this.accessToken = token;
      localStorage.setItem(accessTokenKey, token);
    } catch (error) {
      this.message?.error('Ошибка при сохранении токена');
    }
  };

  unsetAccessToken = () => {
    try {
      this.accessToken = null;
      localStorage.removeItem(accessTokenKey);
    } catch (error) {
      this.message?.error('Ошибка при удалении токена');
    }
  };

  logout = (onLogout?: () => void) => {
    this.unsetAccessToken();
    runInAction(() => {
      this.user = null;
      this.facilities = [];
      this.paginationMeta = null;
      this.referredUsers = [];
      this.referredUsersPaginationMeta = null;
    });

    if (onLogout) {
      onLogout();
    }
  };

  async requestRefill(payload: { amount: number }, onSuccess?: () => void) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isRefillRequestLoading = true;
    });

    try {
      await this.api.requestRefill(payload);

      if (onSuccess) {
        onSuccess();
      }

      if (this.message) {
        this.message.success('Заявка успешно отправлена');
      }
    } catch (error) {
      if (this.message) {
        this.message.error('Ошибка при пополнении баланса');
      }
    } finally {
      runInAction(() => {
        this.isRefillRequestLoading = false;
      });
    }
  }

  async updateUser(
    payload: { firstName: string; lastName: string; email: string | null },
    onSuccess?: () => void,
  ) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isUserUpdateLoading = true;
    });

    try {
      const user = await this.api.updateUser(payload);

      if (onSuccess) {
        onSuccess();
      }

      runInAction(() => {
        this.user = user;
      });

      if (this.message) {
        this.message.success('Обновлено');
      }
    } catch (error) {
      if (this.message) {
        this.message.error('Ошибка');
      }
    } finally {
      runInAction(() => {
        this.isUserUpdateLoading = false;
      });
    }
  }

  async loadUserFacilities(page = 1, showLoader = true) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      if (showLoader) {
        this.isUserFacilitiesLoading = true;
      }
      this.hasUserFacilityError = false;
    });

    try {
      const [facilities, paginationMeta] =
        await this.api.getUserFacilities(page);

      runInAction(() => {
        this.facilities = facilities;
        this.paginationMeta = paginationMeta;
      });
    } catch {
      runInAction(() => {
        this.hasUserFacilityError = true;
      });
    } finally {
      runInAction(() => {
        this.isUserFacilitiesLoading = false;
      });
    }
  }

  async onboardUser(payload: OnboardUserProps) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isUserOnboardLoading = true;
    });

    try {
      const user = await this.api.onboardUser(payload);

      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      if (this.message) {
        this.message.error('Ошибка');
      }
    } finally {
      runInAction(() => {
        this.isUserOnboardLoading = false;
      });
    }
  }

  async loadReferredUsers(referrerId: string, page = 1) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isReferredUsersLoading = true;
      this.hasUserFacilityError = false;
    });

    try {
      const [referredUsers, paginationMeta] = await this.api.getReferredUsers(
        referrerId,
        page,
      );

      runInAction(() => {
        this.referredUsers = referredUsers;
        this.referredUsersPaginationMeta = paginationMeta;
      });
    } catch {
      runInAction(() => {
        this.hasReferredUsersError = true;
      });
    } finally {
      runInAction(() => {
        this.isReferredUsersLoading = false;
      });
    }
  }

  setUser = (user: User) => {
    runInAction(() => {
      this.user = user;
    });
  };
}
